import { EventFields, PlayerListFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetEventAndPlayers = gql`
  query event($id: ID!, $locale: String) {
    event(id: $id) {
      ...EventFields
      ...PlayerListFields
    }
  }
  ${EventFields}
  ${PlayerListFields}
`
